import React from "react"
import "../../src/components/styles/dummypage.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Page two"
      description={
        "We care about your privacy. Our privacy policy will help you understand what information is collected, how it’s used and what choices you have."
      }
    />
    <main className={"security"}>
      <h1 class="heading">
        <a name="privacypolicy">Privacy policy</a>
      </h1>
      <p class="text">
        This Privacy Policy applies solely to information collected through the
        web site located at Kubric.io and the Kubric App(s) (collectively, the
        web site and the app, the “Platform”); whether accessed via computer,
        mobile device or other technology. The Platform is offered by Kubric
        LLC, who is the data controller (“Kubric”, “we”, “us”, “our”). This
        Privacy Policy explains what information may be collected through the
        Platform, how such information may be used and/or shared with others,
        how we safeguard it and how you may access and control its use in
        connection with our marketing communications and business activities.
      </p>
      <h2 class="heading">
        <a name="informationwecollect">Information we collect</a>
      </h2>
      <p class="text">
        INFORMATION YOU GIVE US When you use the Platform, for example to
        register to create an account, update your information, sign up for a
        subscription, use certain features, access or download content, purchase
        items or enter into promotions, refer others to the Platform, interact
        with us via third party application or social media sites, request
        customer support or otherwise communicate with us, we or our service
        providers or business partners may collect information that relates to
        you, including personal information, such as your name, e-mail address,
        telephone number, mobile phone number, credit card or debit card
        details, geographic location, mailing address, date of birth, gender,
        demographic information, photos, videos, social media account details
        and other information from your device. You may decide to provide us
        with another person's email or phone number so that person may be
        invited to join Kubric or so that we may facilitate your communication
        with other people through the service. Local law may require you to seek
        the consent of your contacts to provide their personal information to
        SaKubricavn, who may use that information in accordance with the terms
        of this Privacy Policy. INFORMATION COLLECTED AUTOMATICALLY Whenever you
        visit or interact with the Platform, Kubric, as well as any third-party
        advertisers and/or service providers, may use a variety of technologies
        that automatically or passively collect information about how the
        Platform is accessed and used (“Usage Information”). Usage Information
        may include information collected in the following ways: Log Files:
        Information is logged about your use of the Platforms, including your
        browser type, operating system, activations, content you purchase,
        access, download or burn, access and listening times, the page served
        and the preceding page views. This statistical usage data provides us
        with information about the use of the Platform, such as how many
        visitors visit a specific page on the Platform, how long they stay on
        that page, and which hyperlinks, if any, they “click” on. It also
        provides us with information about your interactions with songs,
        playlists, audiovisual content and other Kubric users, as well as
        advertising and products or services offered through or linked from the
        Platform. This information helps us keep our Platform fresh and
        interesting to our visitors and tailor content to a visitor’s interests.
        Location Information: We may collect information about the location of
        your device when you access or use the Platform; or if you choose to
        turn on your Bluetooth, Wi-Fi or other geolocation functionality when
        you use the App, subject to your consent, the App may collect and use
        your geolocation information. We may share your location with certain
        third-parties for analytics and advertising purposes. Device
        Identifiers: Kubric may automatically collect your IP address or other
        unique identifier (“Device Identifier”) for the computer, mobile device,
        tablet or other device (collectively, “Device”) you use to access the
        Platform, including the hardware model and mobile network information. A
        Device Identifier is a number that is automatically assigned to your
        Device when you access a web site or its servers, and includes AD-IDs or
        IDFAs, and our computers identify your Device by its Device Identifier.
        We may use a Device Identifier to, among other things, administer the
        Platform, help diagnose problems with our servers, analyze trends, track
        users’ web page movements, help identify, and gather broad demographic
        information for aggregate use. Cookies, Pixel Tags The technologies used
        on the Platform to collect Usage Information, including Device
        Identifiers, may include but are not limited to: cookies (data files
        placed on a Device when it is used to visit the Platform), mobile
        analytics software and pixel tags (transparent graphic image, sometimes
        called a web beacon or tracking beacon, placed on a web page or in an
        email, which indicates that a page or email has been viewed). Cookies
        may also be used to associate you with social networking sites and, if
        you so choose, enable interaction between your activities on the
        Platform and your activities on such social networking sites. We, or our
        vendors, may place cookies or similar files on your Device for security
        purposes, to collect information enabling digital rights management, to
        facilitate site navigation and to personalize your experience while
        visiting our Platform (such as allowing us to select which content, ads
        or offers are most likely to appeal to you, based on your interests,
        preferences, location, or demographic information). Personalized content
        may also include content from the providers of goods and services that
        you may be able to purchase through the Platform or otherwise (who may
        pay us to show you such content). Similarly, we may use analytics
        platforms and other tracking technologies to help us track and analyze
        usage of our Platform, using cookies, pixels and other tracking
        technologies. INFORMATION RECEIVED FROM OTHER SOURCES We also may obtain
        information from other sources and combine that with information we
        collect through the Platform. For example, if you create an account
        using your credentials from a third-party social media site, we will
        have access to certain information from that site, such as your name,
        profile and account information, such as lists of your friends, 'likes',
        comments you have shared, groups and location, in accordance with the
        authorization procedures determined by such third-party social media
        site. Services like Facebook Connect give you the option to post
        information about your activities on our Platform to your profile page
        to share with others within your network. In addition, we may receive
        information about you if other users of a third party application or
        website give us access to their profiles and you are one of their
        'connections' or information about you is otherwise accessible through
        your 'connections' web page, profile page, or similar page on a social
        networking or other third party website or interactive service. You may
        integrate your Kubric account with third party applications and sites.
        If you do, we may receive similar information related to your
        interactions with the Kubric service on the third party application, as
        well as information about your publicly available activity on the third
        party application or site. We may also receive information about you
        from our partners and service providers, (including, for example,
        business partners, analytics vendors, advertising networks and search
        information providers) which we use to personalize your Kubric
        experience, to measure ad quality and responses, and to display ads that
        are more likely to be relevant to you.
      </p>
      <h2 class="heading">
        <a name="useofcollectedinformation">Use of collected information</a>
      </h2>
      <p class="text">
        We may use the information about you for a variety of business purposes,
        including for example, to: respond to your questions and requests and
        provide customer service; provide you with access to certain features of
        the Platform; deliver the services you request, process transactions and
        send you related information; verify your identity; communicate with you
        about your activities on the Platform; tailor content and promotions
        offered by Kubric and others, and provide news and other information we
        think will be of interest to you; perform market research, Platform
        analytics and operations; personalize and improve the Platform and
        products, services and advertising (including for third party products
        and services) made available on or outside the Platform (including on
        other sites/ apps that you visit), for example by providing personalized
        or localized content, recommendations, features, and provide content or
        features that match your interests and preferences; link or combine with
        information we receive from others to help understand your needs and to
        provide you with better service; contact you with regard to your use of
        the Platform and interest in certain products and services, and, in our
        discretion, changes to any Kubric policy; for purposes disclosed at the
        time you provide your personal information or otherwise with your
        consent.
      </p>
      <h2 class="heading">
        <a name="sharingofinformation">Sharing of information</a>
      </h2>
      <p class="text">
        The information collected or generated through your use of the Platform
        may be shared by you or by us as described below. SHARING OF INFORMATION
        BY YOU Kubric is a social service that offers many ways to find, enjoy,
        and share content. Your name and/or username, profile picture, who you
        follow, your followers, and your Kubric user profile will be publicly
        available. Your Activities: Your activity on the Platform (e.g., music
        you listen to, music you share), playlists created or followed, and
        content you post to the Platform are made publicly available by default
        and can be accessed by users who follow you or view your Kubric profile
        and will also be displayed publicly in third-party search engine results
        and may appear together with other public profile information, such as
        your playlists, picture and name and/or username. Although this
        information is made public by default, in the “Your Choices” section
        below, we describe the controls that you can use to limit the sharing of
        your information. Third Party Platforms: If your Kubric account is
        connected to a third party application or social medial platform, Kubric
        may automatically share your activity with that service, including the
        content you use and such information may be attributed to your account
        on the third party platform and published on such service. Likewise, if
        you log into a third party application with your Kubric account, that
        third party application may have access to certain information such as
        your Kubric playlists and activity. A third party platform’s use of
        information collected from you (or as authorized by you) is governed by
        the third party platform’s privacy policies and your settings on the
        relevant service. SHARING OF INFORMATION BY US We may share aggregate
        user statistics, demographic information, and Usage Information with
        third parties. We may also share your information as disclosed at the
        time you provide your information, as set forth in this Privacy Policy
        and in the following circumstances: Third Parties: In order to carry out
        your requests, to make various features, services and materials
        available to you through the Platform, and to respond to your inquiries,
        we may share your information with third parties that perform functions
        on our behalf, such as companies that: host or operate our Platform;
        payment processors; analyze data; provide customer service; sponsors or
        other third parties that participate in or administer our promotions.
        Your information may also be used by us or shared with our sponsors,
        partners, advertisers, advertising networks, advertising servers, and
        analytics companies or other third parties in connection with marketing,
        promotional, and other offers, as well as product information, and for
        such third party’s advertising, analytics and market research. These
        advertisers, advertising networks, advertising servers, and analytics
        companies use various technologies to collect data in order to send (or
        serve) relevant ads to users on our Platforms, or on platforms or
        websites operated by third parties. These technologies may include the
        placement of cookies or web beacons, the use of unique or non-unique
        non-personal identifiers, or the use of other technologies on our
        Platforms, which may be used to track user behavior, to track how our
        Platforms are being used, to link various Devices you may use, build
        consumer profiles and possibly to serve you more relevant ads. This
        Privacy Policy does not cover the use of various technologies by
        advertisers, advertising networks, advertising servers, and analytics
        companies. We may also share your information with the rights holders
        that license content to Kubric to stream on the Platform. Your Agreement
        To Have Your Personal Information Shared: While on our Platform, you may
        have the opportunity to opt-in to receive information and/or marketing
        offers from someone else or to otherwise consent to the sharing of your
        information with a third party. If you use the social sharing features
        of the Platform, your information may be shared with certain of your
        friends or groups. If you agree to have your information shared, your
        information will be disclosed to the third party and the information you
        disclose will be subject to the privacy policy and business practices of
        that third party. Business Transfers: Your information may also be used
        by us or shared with our subsidiaries and affiliates for internal
        reasons, primarily for business and operational purposes. As we continue
        to develop our business, we may sell or purchase assets. If another
        entity acquires us or all or substantially all of our assets, or assets
        related to the Platform, your information may be disclosed to such
        entity as part of the due diligence process and will be transferred to
        such entity as one of the transferred assets. Also, if any bankruptcy or
        reorganization proceeding is brought by or against us, all such
        information may be considered an asset of ours and as such may be sold
        or transferred to third parties. Legal Disclosure: Kubric may transfer
        and disclose your information to third parties to comply with a legal
        obligation; when we believe in good faith that the law requires it; at
        the request of governmental authorities conducting an investigation; to
        verify or enforce our Terms of Use or other applicable policies; to
        respond to an emergency; or otherwise to protect the rights, property,
        safety, or security of third parties, visitors to our Platform or the
        public.
      </p>
      <h2 class="heading">
        <a name="yourchoices">Your choices</a>
      </h2>
      <p class="text">
        COOKIES &amp; TRACKING To learn how you may be able to reduce the number
        of cookies you receive from us or third parties, or delete cookies that
        have already been installed in your browser’s cookie folder, please
        refer to your browser’s help menu or other instructions related to your
        browser to see if you can reject or disable such cookies. You can also
        learn more about cookies by visiting allaboutcookies.org which includes
        additional useful information on cookies and how to block cookies using
        different types of browser. Removing or rejecting browser cookies does
        not necessarily affect third-party flash cookies used in connection with
        our Platform. To delete or disable flash cookies, please visit adobe for
        more information. If you do disable or opt out of receiving cookies,
        please be aware that some features and services on our Platform may not
        work properly because we may not be able to recognize and associate you
        with your account(s). In addition, the offers we provide when you visit
        us may not be as relevant to you or tailored to your interests. To learn
        how to change your Device Identifier, please refer to your device’s help
        menu or other instructions. Some browsers have a “Do Not Track” feature
        that lets you tell websites that you do not want to have your online
        activities tracked. These features are not yet uniform, so note that our
        systems are not configured to recognize Do Not Track headers or signals
        from some or all browsers. DATA SHARING AND COMMUNICATIONS You may
        always direct us not to share your personal information with third
        parties, not to use your information to provide you with information or
        offers, or not to send you newsletters, e-mails or other communications
        by: Sending us an e-mail at: welisten@Kubric.io Following the removal
        instructions in the communication that you receive. Your opt-out request
        will be processed within 30 days of the date on which we receive it.
        Please note that if you opt-out of various uses of your information the
        service we provide to you and relevant offers may be impacted. Also, If
        you opt out of receiving promotional offers, we may still send you
        non-promotional communications, such as those about your account or our
        ongoing business relations. LOCATION INFORMATION By downloading our App
        or using the Platform, you consent to our collection of location
        information and sharing with third parties for analytics and advertising
        purposes. If you initially consent to our collection of location
        information, you can subsequently stop the collection of this
        information at any time by going to the setting feature on your Device
        and changing your preferences. If you do so, certain features of our App
        may no longer function. You also may stop our collection of location
        information by following the standard uninstall process to remove our
        App from your device. ACCESS TO YOUR INFORMATION If you wish to verify,
        correct, or update any of your personal information collected through
        the Platform, you may contact us at the above address or e-mail. The
        information associated with you that is made publicaly available (such
        as your photo or user name) depends on your login method. For example,
        if you do not want your photo to be made publicaly available in
        connection with to your email address, you can login by phone or email.
        In accordance with our routine record keeping, we may delete certain
        records that contain personal information you have submitted through the
        Platform. We are under no obligation to store such personal information
        indefinitely and disclaim any liability arising out of, or related to,
        the destruction of such personal information. In addition, you should be
        aware that it is not always possible to completely remove or delete all
        of your information from our databases without some residual data
        because of backups and other reasons.
      </p>
      <h2 class="heading">
        <a name="children">Children</a>
      </h2>
      <p class="text">
        The Platform is not directed to children under 13. We do not knowingly
        collect personal information from anyone under 13 years of age. If we
        determine upon collection that a user is under this age, we will not use
        or maintain his/her personal information without the parent/guardian’s
        consent.
      </p>
      <h2 class="heading">
        <a name="securityofyourinformation">Security of your information</a>
      </h2>
      <p class="text">
        We use certain reasonable security measures to help protect your
        personal information. However, no electronic data transmission or
        storage of information can be guaranteed to be 100% secure. Please note
        that we cannot ensure or warrant the security of any information you
        transmit to us, and you use the Platform and provide us with your
        information at your own risk.
      </p>
      <h2 class="heading">
        <a name="othersites">Other sites</a>
      </h2>
      <p class="text">
        The Platform may contain links to other sites that we do not own or
        operate. This includes links from service providers, advertisers,
        sponsors and partners that may use our logo(s) as part of a co-branding
        agreement. We do not control, recommend or endorse and are not
        responsible for these sites or their content, products, services or
        privacy policies or practices. These other web sites may send their own
        cookies to your Device, they may independently collect data or solicit
        personal information and may or may not have their own published privacy
        policies. You should also independently assess the authenticity of any
        site which appears or claims that it is one of our Platforms (including
        those linked to through an email or social networking page).
      </p>
      <h2 class="heading">
        <a name="consenttoprocessing&amp;transfers">
          Consent to processing &amp; transfers
        </a>
      </h2>
      <p class="text">
        Kubric is a global business and processes, transfers and stores
        information about our users on servers located in a number of countries.
        As a result, Kubric may share or transfer your information with third
        parties located in countries other than your home country. Your personal
        information may therefore be subject to privacy laws that are different
        from those in your country of residence. By using the Platform, or
        providing us with any information, you consent to the collection,
        processing, maintenance and transfer of such information in and to
        India.
      </p>
      <h2 class="heading">
        <a name="changes">Changes</a>
      </h2>
      <p class="text">
        Any changes we may make to our Privacy Policy will be posted on this
        page. Please check back frequently to see any updates or changes to our
        Privacy Policy. If you do not agree or consent to these updates or
        changes, do not continue to use the Platform.
      </p>
      <h2 class="heading">
        <a name="howtocontactus">How to contact us</a>
      </h2>
      <p class="text">
        If you have any questions or concerns regarding this Privacy Policy,
        please contact us at: welisten@Kubric.io
      </p>
    </main>
  </Layout>
)
